import axios from 'axios'
const tocken = localStorage.getItem("tck")
// const ConfigBaseURL = 'http://192.168.2.20:8090/' //默认路径，这里也可以使用env来判断环境
// const ConfigBaseURL = 'http://localhost:8090/'
const ConfigBaseURL = 'http://124.71.212.84:8090/'
//使用create方法创建axios实例
export const Service = axios.create({
    timeout: 7000, // 请求超时时间
    baseURL: ConfigBaseURL,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "authorization": "Bearer " + tocken
    }
})
// // 添加请求拦截器
// Service.interceptors.request.use(config => {
//
//     return config
// })
// // 添加响应拦截器
// Service.interceptors.response.use(response => {
//
//     // console.log(response)
//     return response.data
// }, error => {
//     console.log('TCL: error', error)
//     const msg = error.Message !== undefined ? error.Message : ''
//
//     return Promise.reject(error)
// })
